<template>
  <v-container>
    <v-row class="d-flex flex-row container">
      <v-col cols="12" sm="6" md="6" lg="6">
        <h3
          class="font-weight-bold blue-grey--text font text-uppercase font-weight-medium font-md"
        >
          Deleted Members
        </h3>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6">
        <v-card class="pa-2" style="height: 120px" outlined tile>
          <div class="container">
            <v-col cols="12" sm="6" md="12">
              <v-text-field
                label="Search Staff....."
                dense
                v-model="search"
                outlined
              ></v-text-field>
            </v-col>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      class="d-flex flex-row container"
      style="margin-top: -80px; margin-right:10px"
    >
      <v-col cols="12" sm="12" lg="12" md="12" xl="12">
        <v-toolbar
          color="transparent"
          flat
          elevation="0"
          class="mb-6 title mt-2"
          height="55px"
        >
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="membersList"
          :loading="pageLoading"
          :server-items-length="paginate.total"
          :options.sync="options"
          class="font box-shadow-light font-weight-medium text-capitalize  mx-1 mt-n2"
          loading-text="Loading... Please wait"
          :search="search"
          :footer-props="{
            itemsPerPageOptions: [10, 15, 20],
          }"
        >
          <template #item.avatar="{item}">
            <v-avatar color="primary" size="40" class="ma-1 font">
              <span class="white--text font font-md subtitle-1 ">{{
                item.firstName
                  ? item.firstName
                  : item.lastName | firstLetterFunction
              }}</span>
            </v-avatar>
          </template>
          <template #item.name="{item}">
            <span class=" font font-sm "
              >{{ item.firstName ? item.firstName : '' }}
              {{ item.lastName ? item.lastName : '' }}</span
            >
          </template>
          <template #item.accountNumber="{item}">
            <v-btn small text color="primary">
              <span class=" font font-sm">{{ item.code }}</span>
            </v-btn>
          </template>
          <template #item.phone="{item}">
            <v-btn small text color="primary">
              <span class=" font font-sm">{{
                item.phoneNo ? item.phoneNo : item.mobileAlert
              }}</span>
            </v-btn>
          </template>
          <template #item.deletedAt="{item}">
            <v-btn small text color="primary">
              <span class=" font font-sm">{{
                item.deletedAt | timestamp
              }}</span>
            </v-btn>
          </template>
          <template #item.actions="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :loading="isLoading"
                  @click="$store.dispatch('members/restore', item._id)"
                  icon
                  fab
                  small
                >
                  <i class="material-icons-outlined font-md">restore</i>
                </v-btn>
              </template>
              <span class=" font font-sm">Restore Deleted Member</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>

<script>
import moment from 'moment';
import { loadComponent } from '@/utils/helpers';
import { mapGetters } from 'vuex';
import SnackBarMixins from '../../../../mixins/SnackBarMIxins';
export default {
  name: 'AllStaffs',
  data() {
    return {
      options: {},
      search: '',
    };
  },
  components: {
    SnackBar: loadComponent(`component/SnackBar`),
  },

  computed: {
    ...mapGetters({
      allMembers: 'members/getAllMembers',
      pageLoading: 'getIsPageLoading',
      isLoading: 'getIsLoading',
      paginate: 'members/getPagination',
    }),
    form() {
      return {
        name: this.name,
        phone: this.phone,
        accountNumber: this.accountNumber,
      };
    },
    membersList() {
      return this.allMembers.filter(searchTerm => {
        const query = `${searchTerm?.firstName} ${searchTerm?.lastName}`;
        return query?.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
      });
    },

    headers() {
      return [
        {
          text: '',
          value: 'avatar',
          filterable: false,
          sortable: false,
        },
        {
          text: 'Name',
          value: 'name',
          filterable: true,
          sortable: false,
        },

        {
          text: 'Account Number',
          value: 'accountNumber',
          filterable: false,
          sortable: false,
        },
        { text: 'Phone', value: 'phone', filterable: false, sortable: false },
        {
          text: 'Deleted At',
          value: 'deletedAt',
          filterable: false,
          sortable: false,
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ];
    },
  },
  filters: {
    status(value) {
      return !value ? 'Active' : 'Inactive';
    },
    timestamp(value) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    },
    firstLetterFunction(value) {
      return value.charAt(0).toUpperCase();
    },
  },
  mixins: [SnackBarMixins],
  watch: {
    options: {
      handler(value) {
        const { itemsPerPage, page } = value;
        const query = `?page=${page}&size=${itemsPerPage}`;
        this.$store.dispatch('members/listAllMembersWithTrash', query);
      },
      deep: true,
    },
  },

  methods: {},
  created() {
    const query = `?page=1&size=30`;
    this.$store.dispatch('members/listAllMembersWithTrash', query);
  },
};
</script>

<style scoped>
.createNew {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  alignt-content: stretch;
  margin-right: 25px;
}

.box {
  height: auto;
  width: auto;
  padding: 5%;
}
</style>
